import React from "react";
import Layout from "../../components/layout";
import ProductSpec from "../../components/productSpec";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { graphql } from 'gatsby';
const Enamels = ({ data }) => (
  <div>
    <Layout>
      <Row>
        <Col>
          <h1>Enamels</h1>
          <p>Enamel paint is paint that air dries to a hard, usually glossy, finish, used for coating surfaces that are outdoors or otherwise subject to hard wear or variations in temperature; it should not be confused with decorated objects in “painted enamel”, where vitreous enamel is applied with brushes and fired in a kiln.</p>
        </Col>
      </Row>
      {/* Synthetic enamels */}
      <ProductSpec heading={'Synthetic Enamels'} data={'Synthetic Enamel is anti-corrosive in nature and is used for high performance coating with excellent adhesion to metal, concrete, FRP and wood. These enamels are used in coating various types of metals and wooden products so as to ensure high durability.'} link={'products/syntheticEnamels'} image={data.syntheticEnamelsMain.childImageSharp.fluid}></ProductSpec>
      
      {/* Stoving Enamels */}
      <ProductSpec heading={'Stoving Enamels'} data={'Stoving enamel paints are similar to synthetic enamel except they cure through thermosetting resins i.e. on application of heat in an oven at 120c for 30 minutes stoving schedule. The shades are available as per buyer specifications. Alkyd butylated amino resins have good flexibility and adhesion, fair gloss and chemical resistance.'} link={'products/stovingEnamels'} image={data.NCPaintsMain.childImageSharp.fluid}></ProductSpec>

      

      {/* Quick Dry */}
      <ProductSpec heading={'Quick Dry'} data={'Quick Dry Gloss is a beautiful gloss paint. It is self-undercoating, wipeable, non-yellowing and suitable for use on interior and exterior wood and metal. Thanks to its Quick Dry, low odour formulation it is both convenient and pleasant to use.'} link={'products/quickDry'} image={data.quickDryMain.childImageSharp.fluid}></ProductSpec>

      
      {/* NC Paints */}
      <ProductSpec heading={'N.C Paints'} data={'N.C Paint is a high performance coating suitable for auto re-finishes and also finds utility in many other industrial applications .'} link={'products/ncPaints'} image={data.NCPaintsMain.childImageSharp.fluid}></ProductSpec>

      
      {/* Epoxy Paints */}
      <ProductSpec heading={'Epoxy Paints'} data={'Epoxy paints require mixing two parts. The user must mix in proper proportions and allow the product to stand the recommended time in order for it to dry. Once mixed, it is possible to apply the paints in a variety of ways. Most of the time an individual uses a roller on a garage floor.'} link={'products/epoxyPaints'} image={data.epoxyPaintsMain.childImageSharp.fluid}></ProductSpec>
      
      {/* P.U Paints */}
      <ProductSpec heading={'P.U Paints'} data={'Two pack coatings consist of one component comprises the polyol component,which is a hydroxy functional polymer,while the second component is essentially isocyanate polymer along with moisture free solvents.'} link={'products/puPaints'} image={data.PUPaintsMain.childImageSharp.fluid}></ProductSpec>

    </Layout>

  </div>


)
export default Enamels;
export const query = graphql`
query {
    syntheticEnamelsMain: file(relativePath: { eq: "syntheticEnamelsMain.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2:file(relativePath:{eq:"gatsby-icon.png"}){
       childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    epoxyPaintsMain:file(relativePath:{eq:"epoxyPaintsMain.jpg"}){
      childImageSharp {
       fluid(maxWidth: 300) {
         ...GatsbyImageSharpFluid
       }
     }
   }

   NCPaintsMain:file(relativePath:{eq:"NCPaintsMain.jpg"}){
    childImageSharp {
     fluid(maxWidth: 300) {
       ...GatsbyImageSharpFluid
     }
   }
 }

 PUPaintsMain:file(relativePath:{eq:"PUPaintsMain.jpg"}){
  childImageSharp {
   fluid(maxWidth: 300) {
     ...GatsbyImageSharpFluid
   }
 }
}

quickDryMain:file(relativePath:{eq:"quickDryMain.jpg"}){
  childImageSharp {
   fluid(maxWidth: 300) {
     ...GatsbyImageSharpFluid
   }
 }
}

 
  }
`
