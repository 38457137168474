import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import "../components/header.css"
import Image from "react-bootstrap/Image";
// import logo from "../images/Threesons logo-1.png";
import logo from "../images/finalLogo.png";

const ListLink = props => (
  <li  style={{ display: `inline-block`, marginRight: `1rem`,marginLeft:"1rem" ,marginTop:"8px" }}>
    <Link  className="listLink" partiallyActive activeClassName="active" to={props.to}>{props.children}</Link>
  </li>
)
const Header = ({ siteTitle},{data}) => (
<>
  <Navbar  className="headerStyles" expand="lg" >
  <Navbar.Brand className="companyLogo" >
  <Link to="/about" ><Image src={logo} width={200}   alt={logo}></Image></Link>
  
  </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="ml-auto" > 
        <ListLink to="/about/">About</ListLink>
        <ListLink to="/careers/">Careers</ListLink>
        <ListLink to="/products/">Products</ListLink>
        <ListLink to="/contact/">Contact</ListLink>
        {/* <ListLink to="/enquiry/">Enquiry</ListLink> */}
        <ListLink to="/gallery/">Gallery</ListLink>
        <ListLink to="/chemicalDivision/">Chemical Division</ListLink>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
   
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;





