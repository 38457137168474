import React from 'react'
import "./footer.css";
import 'bootstrap/dist/css/bootstrap.css';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
const Footer = () => (
  //   <Navbar bg="light" variant="light" fixed="bottom" expand="sm">
  //   <Nav.Item  >
  //      Email: <a href="mailto:info@threesons.in">info@threesons.in</a>
  //   </Nav.Item>
  //   <Nav.Item>
  //       Phone: <a href="tel:91-80-278-332-07">91-80-278-332-07</a>
  //     </Nav.Item>

  // </Navbar>
  <footer>
   <Row className="footer centerText" >
     <Col xs={12} >
        <h5>Email: <a href="mailto:info@threesons.in">info@threesons.in</a></h5>
     </Col>
     <Col xs={12}>
        <h5>Phone: <a href="tel:91-80-278-332-07">91-80-27833207</a></h5>
     </Col>
     <Col xs={12}>
        <p>Made with <span >&hearts; </span>by Rearm Labs</p>
     </Col>
   </Row>
  </footer>
)
export default Footer;