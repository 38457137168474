/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Footer from './footer';
import Header from "./header"
import "./layout.css"
import Container from "react-bootstrap/Container";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>

        <Helmet htmlAttributes={{ lang : 'en' }}>
          <title>Threesons Paints & Chemicals Pvt Ltd</title>
          <meta name="title" content="Threesons Paints & Chemicals Pvt Ltd" />
          <meta name="description" content="Threesons is an ISO 9001-2000 certified company with fully automated art of manufacturing at Bommasandra – near the IT hub, Bangalore. Manufacturing decorative and industrial paints, construction chemicals, epoxy chemicals and supplying all kinds of industrial chemicals." />

          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://threesons.in/" />
          <meta property="og:title" content="Threesons Paints & Chemicals Pvt Ltd" />
          <meta property="og:description" content="Threesons is an ISO 9001-2000 certified company with fully automated art of manufacturing at Bommasandra – near the IT hub, Bangalore. Manufacturing decorative and industrial paints, construction chemicals, epoxy chemicals and supplying all kinds of industrial chemicals." />
          <meta property="og:image" content="" />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://threesons.in/" />
          <meta property="twitter:title" content="Threesons Paints & Chemicals Pvt Ltd" />
          <meta property="twitter:description" content="Threesons is an ISO 9001-2000 certified company with fully automated art of manufacturing at Bommasandra – near the IT hub, Bangalore. Manufacturing decorative and industrial paints, construction chemicals, epoxy chemicals and supplying all kinds of industrial chemicals." />
          <meta property="twitter:image" content="" />
          <link rel="canonical" href="https://threesons.in/" />
        </Helmet>
        <Header siteTitle='Threesons' />


        <Container className="container">

          {children}
          
        </Container>
        <Container fluid>
        <Footer></Footer>
        </Container>



      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
