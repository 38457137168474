import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "gatsby";
import Img from "gatsby-image"
const productSpec = (props) => (
    <>
    <Row>
        <Col><h2>{props.heading}</h2>
        </Col>
    </Row>
    <Row>
        <Col xs={12} sm={8}>
        <p>{props.data}</p>
        <Link to={props.link}>View Products</Link>
        </Col>
        <Col xs={12} sm={4} md={{span:3,offset:1}}><Img fluid={props.image}></Img>
        </Col>
    </Row>
    <hr />
       </>
   
)

export default productSpec;
